import "./App.css";
import React, { Component } from "react";
import axios from "axios";


class App extends Component {
  state = {
    selectedFile: null,
    fileUploadedSuccesfully: false,
  };

  onFileChange = (event) => {
    console.log(event);
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "demo file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    console.log(formData);
    // call the api
    axios
      .post(
        "https://p9blp1rmy8.execute-api.us-east-1.amazonaws.com/prod/file-upload/",
        formData
      )
      .then(() => {
        this.setState({ selectedFile: null });
        this.setState({ fileUploadedSuccesfully: true });
      });
  };

  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>Detalles del archivo:</h2>
          <p>Nombre: {this.state.selectedFile.name}</p>
          <p>Tipo: {this.state.selectedFile.type}</p>
        </div>
      );
    } else if (this.state.fileUploadedSuccesfully) {
      return (
        <div>
          <br />
          <h4>El archivo ha sido cargado</h4>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Selecciona un archivo y haz click en cargar</h4>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <h1>Cargar archivos csv</h1>
        <div className="buttons">
          <input
            className="inputFile"
            type="file"
            onChange={this.onFileChange}
          />
          <button onClick={this.onFileUpload}>Cargar</button>
        </div>
        {this.fileData()}
      </div>
    );
  }
}

export default App;
